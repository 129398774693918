<template>
  <v-app id="404">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <div class="text-md-center">
            <h1>404</h1>
            <h2 class="my-3 headline ">Ops! Página não localizada.</h2>
            <div>
              <v-btn color="primary" to="/">Voltar?</v-btn>
            </div>  
          </div>
        </v-layout>
      </v-container>
  </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>